import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mpCodeTmpList, mpCodeTmpSync, mpCodeTmpManage } from '@/api/mpCodeTmp';
export default {
  name: "mpCodeTmps",
  data: function data() {
    return {
      data: [],
      loading: false,
      dialogNewVisible: false,
      submitting: false,
      newForm: {
        process: 0,
        app_ids: []
      }
    };
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    requestData: function requestData() {
      var _this = this;

      this.loading = true;
      mpCodeTmpList().then(function (res) {
        _this.loading = false;
        _this.data = res.data;
      }).catch(function (e) {
        _this.$message.error(e.message);
      });
    },
    handleSync: function handleSync() {
      var _this2 = this;

      mpCodeTmpSync().then(function (res) {
        _this2.$message.success(res.msg);

        _this2.requestData();
      }).catch(function (e) {
        _this2.$message.error(e.message);
      });
    },
    handleUpgrade: function handleUpgrade() {
      this.newForm = {
        process: 0,
        app_ids: []
      };
      this.dialogNewVisible = true;
    },
    handleSave: function handleSave(formName) {
      var _this3 = this;

      if (this.newForm.app_ids.length > 0) {
        var appIds = [];
        this.newForm.app_ids.forEach(function (item) {
          appIds.push(parseInt(item));
        });
        this.newForm.app_ids = appIds;
      }

      this.submitting = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          mpCodeTmpManage(_objectSpread({}, _this3.newForm)).then(function (res) {
            _this3.submitting = false;

            _this3.$message.success(res.msg);
          }).catch(function (e) {
            _this3.submitting = false;

            _this3.$message.error(e.message);
          });
        } else {
          _this3.submitting = false;
        }
      });
    }
  }
};